.App {
  --bg-color: #ffe8f3;
  --text-color: #c0577a;
  --hover-color: #aa335b;

  text-align: left;
  height: 100%;
  display: flex;
  justify-content: center;
  color: var(--text-color);
  /* background: url('./images/backgroundTile.jpeg') repeat fixed center; */
  padding: 22px;
}

body, html {
  /* background by Ilham Hari
  https://www.vecteezy.com/free-vector/floral-repeat */
  background: url('./images/background.jpg') repeat fixed center;
  background-size: 80%;
  /* background: url(https://gdurl.com/h0tk) no-repeat fixed center; */
  height: 100%;
  font-family: 'Nunito', sans-serif;
}

h1 {
  font-family: 'Merriweather', serif;
  font-size: 35px;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-family: 'Merriweather', serif;
  margin: 0;
  font-size: 23px;
}

h3 {
  font-family: 'Merriweather', serif;
  margin: 0;
}

.link {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
  display: inline;
}

.link:hover {
  color: var(--hover-color);
}

.main {
  background-color: var(--bg-color);
  display: flex;
  flex-flow: column wrap;
  width: 320px;
  padding: 30px;
  overflow: auto;
  box-shadow: 10px -10px var(--text-color);
}

.button {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--bg-color);
  background-color: var(--text-color);
  padding: 8px 15px;
  border-radius: 5px;
  margin: 5px 0;
}

.button:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

/* home page */
.list-links {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: 800;
  padding: 10px 0;
  color: var(--text-color);
  text-decoration: none;
  display: flex;
}

.list-links:hover {
  color: var(--hover-color);
}

/* payment icons (in HowToPay.js) */
.paymentIcons {
  width: 70px;
  mix-blend-mode: luminosity;
}

.paymentIcons:hover {
  cursor: pointer;
  filter: brightness(85%);
}

/* social icons */
.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  background: var(--text-color);
  color: var(--bg-color);
}

.social-icons {
  padding: 8px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 8px;
  border-radius: 50%;
  background: var(--text-color);
  color: var(--bg-color);
}

.social-icons:hover {
  background-color: var(--hover-color);
}

.svg-social-icon {
  filter: invert(81%) sepia(38%) saturate(163%) hue-rotate(299deg) brightness(107%) contrast(105%);
}

.calendly {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 30px;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  background: var(--bg-color);
  color: var(--text-color);
}

.calendly:hover {
  filter: brightness(90%);
}

.calendlySVG {
  filter: invert(40%) sepia(78%) saturate(300%) hue-rotate(289deg) brightness(93%) contrast(94%);
  width: 30px;
  padding-right: 8px;
  margin-left: -8px;
}